import React from 'react';

import Image from '../assets/allan.png';

import { FaInstagramSquare, FaLinkedin ,FaFacebookSquare } from 'react-icons/fa';
import { IoIosMail } from "react-icons/io";

import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Banner = () => {
  return (
    <section className=' lg:h-[80vh] flex items-center min-h-[75vh] lg:min-h-[70vh] flex' id='home'>
      <div className='container mx-auto '>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1 
              variants={fadeIn('up', 0.3)} 
              initial="hidden" 
              whileInView={'show'}
              viewport={{once:false, amount :0.7}} 
              className='text-[55px] font-bold leading-[0.8] lg:text[110px]'>
                ALLAN   
                  <span> DEV</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.4)} 
              initial="hidden" 
              whileInView={'show'}
              viewport={{once:false, amount :0.7}}  
              className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-white mr-4'>
                Je suis
              </span>
              <TypeAnimation sequence={[
                'Développeur',
                2000,
                'Concepteur',
                2000,
              ]}
                speed={50}
                className='text-accent'
                wrapper='span'
                repeat={Infinity} />

            </motion.div>
            <motion.p
            variants={fadeIn('up', 0.5)} 
            initial="hidden" 
            whileInView={'show'}
            viewport={{once:false, amount :0.7}} 
            className='h4'>Développeur Free-lance à la Réunion</motion.p>
            <motion.p 
            variants={fadeIn('up', 0.6)} 
            initial="hidden" 
            whileInView={'show'}
            viewport={{once:false, amount :0.7}}  
            className='mb-8 max-w-lg mx-auto lg:mx-0'>Chaque projet est une occasion de repousser les limites et d’offrir des solutions de programmation à la fois précises et durables.
            </motion.p>
            <motion.div
            variants={fadeIn('up', 0.7)} 
            initial="hidden" 
            whileInView={'show'}
            viewport={{once:false, amount :0.7}} 
            className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
              <a href="#contact" className='btn btn-lg content-center'>Contactez-moi</a>
                  <a href='#work' className='text-gradient btn-link'>
                    Mon portfolio
                  </a>
            </motion.div>
            <motion.div
             variants={fadeIn('up', 0.8)} 
             initial="hidden" 
             whileInView={'show'}
             viewport={{once:false, amount :0.7}} 
            className='flex text-[20px] gap-x-6 maw-w-maw mx-auto lg:mx-0 '>
              <a className='text-white text-opacity-50 hover:text-opacity-100 hover:scale-110'target='_blank' href='mailto:allandev974@gmail.com' title='Lien vers mon Email'><IoIosMail /> </a>
              <a className='text-white text-opacity-50 hover:text-opacity-100 hover:scale-110'target='_blank' href='https://www.instagram.com/allan_dev974/' title='Lien vers mon Intagram'><FaInstagramSquare /> </a>
              <a className='text-white text-opacity-50 hover:text-opacity-100 hover:scale-110'target='_blank' href='https://www.linkedin.com/in/allan-souma%C3%AFli-356488143/' title='Lien vers mon Linkedin'><FaLinkedin /> </a>
              <a className='text-white text-opacity-50 hover:text-opacity-100 hover:scale-110'target='_blank' href='https://www.facebook.com/allan.dev974?locale=fr_FR' title='Lien vers mon Facebook'><FaFacebookSquare /> </a>
            </motion.div>
          </div>
          <motion.div
          variants={fadeIn('down', 0.9)} 
          initial="hidden" 
          whileInView={'show'}
          viewport={{once:false, amount :0.7}}  
          className='hidden lg:flex flex-1 maw-w-[320px] lg:max-w-[482px] md:max-w-[350px]'>
            <img className='rounded-3xl' src={Image} alt='Logo - Allan Dev , Développeur Fullt-Stack à la Réunion' />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
