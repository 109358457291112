import React from "react";

import { BsArrowUpRight } from "react-icons/bs";
import Image from '../assets/services.jpg';

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const services = [
  {
    name: "Création/Refonte de site vitrine",
    description:
      "Pour présenter votre entreprise, vos activités et vos services de manière optimale, et maximiser votre visibilité dans les résultats de recherche Google.",
    link: "Voir plus",
  },
  {
    name: "Création de site e-commerce",
    description:
      "Pour renforcer votre présence en ligne, améliorer la gestion de votre boutique et de vos produits, et atteindre les meilleurs classements sur Google.",
    link: "Voir plus",
  },
  {
    name: "Développement sur mesure",
    description:
      "Création de solutions web sur mesure, comme un convertisseur de PDF, un convertisseur PDF, une plateforme d'envoi d'e-mails, ainsi que des extensions Chrome.",
    link: "Voir plus",
  },
];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row ">
          <motion.div
                    variants={fadeIn('right',0.3)}
                    initial="hidden"
                    whileInView={'show'}
                    viewport={{once:false , amount:0.3}}
           className="flex-1  mix-blend-lighten mb-12 lg:mb-0">
            <h2 className="h2 text-accent mb-6">Mes services </h2>
            <h3 className="h3 max-w-[455px] mb-10">
              Développeur full-stack
            </h3>
            <img className='mt-10 rounded-xl w-4/5' src={Image} alt='' />
          </motion.div>
          <motion.div 
                    variants={fadeIn('left',0.3)}
                    initial="hidden"
                    whileInView={'show'}
                    viewport={{once:false , amount:0.3}}
          className="flex-1">
            <div>
              {services.map((services, index) => {
                const { name, description, link } = services;
                return (
                  <div
                    className="border-b border-white/20 h-[160px] mb-[38px] flex"
                    key={index}
                  >
                    <div className="max-w-[476px]">
                      <h4 className="text-[20px] tracking-wider font-primary text-gradient font-semibold mb-6">
                        {name}
                      </h4>
                      <p className="font-secondary leading-light">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-col flex-1 items-end">
						<a href="#" className="btn w-9 h-9 mb-[42px] flex justify-center items-center"><BsArrowUpRight/></a>
						<a href="#" className="text-gradient text-sm">{link}</a>
					</div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
