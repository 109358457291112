import React from 'react';

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

import Img1 from '../assets/1_1.webp';
import Img2 from '../assets/2_1.webp';
import Img3 from '../assets/3_1.webp';

const Work = () => {
  return(
     <section className='section' id='work'>
        <motion.div 
                  variants={fadeIn('up',0.3)}
                  initial="hidden"
                  whileInView={'show'}
                  viewport={{once:false , amount:0.3}}
        className='container mx-auto'>
          <div className='flex flex-col lg:flex-row gap-x-10'>
            <div className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
              <div className='p-3'>
                <h2 className='h2 leading-tight text-accent'>Mes derniers<br/> Projets </h2>
                <p className='max-w-sm mb-10'>Voici quelques projets réalisés récemment. J'ai également plusieurs autres projets que vous pourrez consulter ultérieurement.</p>
                <button className='btn btn-sm'>Voir tout les projets</button>
              </div>
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                <img className='group-hover:scale-125 transition-all duration-500' src={Img1} alt='Projet Webcup en Symfony, Allan dev Réunion'/>
                <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition all duration-500 z-50'>
                  <span className='text-gradient'>
                  Symfony/Tailwind CSS
                  </span>
                </div>
                <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition all duration-500 z-50'>
                  <span className='text-3xl text-white'>PowerSwap</span>
                </div>
              </div>
            </div>
            <div className='flex-1 flex flex-col gap-y-10 '>
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  <img className='group-hover:scale-125 transition-all duration-500' src={Img2} alt='Projet Agence en Wordpress, Allan dev Réunion'/>
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition all duration-500 z-50'>
                    <span className='text-gradient'>
                    Wordpress/Elementor
                    </span>
                  </div>
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition all duration-500 z-50'>
                    <span className='text-3xl text-white'>Agence Digitale</span>
                  </div>
                </div>
                <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  <img className='group-hover:scale-125 transition-all duration-500' src={Img3} alt='Projet e-commerce en Symfony, Allan dev Réunion'/>
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition all duration-1000 z-50'>
                    <span className='text-gradient'>
                    Symfony/Bootstrap
                    </span>
                  </div>
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition all duration-500 z-50'>
                    <span className='text-3xl text-white'>Plateforme e-commerce</span>
                  </div>
                </div>
            </div>
          </div>
        </motion.div>
      </section>
    );
};

export default Work;
