import React from 'react';
import CountUp from 'react-countup';

import { useInView } from 'react-intersection-observer';

import Image from '../assets/work.jpg';

import {motion} from 'framer-motion';
import { fadeIn } from '../variants';

const Contact = () => {
  const [ref, inView] = useInView({
    threshold:0.5,
  })
  return (
    <section className='section' id='contact' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen '>
          
          <motion.div 
          variants={fadeIn('right',0.3)}
          initial="hidden"
          whileInView={'show'}
          viewport={{once:false , amount:0.3}}
          className='flex-1'>
            <h2 className='h2 text-accent'>Contact</h2>
            {/* <h3 className='h3 mb-4'>
              Je suis Développeur Freelance à la Réunion

            </h3> */}
            <p className='mb-6'>
            N'hésitez pas à me contacter pour discuter de votre projet et des moyens par lesquels je pourrais vous aider.            </p>
            <div className='w-full'>
              
              <div className='flex flex-col my-3'>
                <span className='text-gradient'>Email</span>
                <a className='text-lg font-medium ' target='_blank' href='mailto:allandev974@gmail.com' title='Lien vers mon Email'>allandev974@gmail.com</a>
              </div>
              <div className='flex flex-col my-3'>
                <span className='text-gradient'>Facebook</span>
                <a className='text-lg font-medium ' target='_blank' href='https://www.facebook.com/allan.dev974?locale=fr_FR' title='Lien vers mon Facebook'>
                Allan Dev Réunion
                </a>
              </div>
              <div className='flex flex-col my-3'>
                <span className='text-gradient'>Instagram</span>
                <a className='text-lg font-medium ' target='_blank' href='https://www.instagram.com/allan_dev974/' title='Lien vers mon instagram'>
                Allan_dev974
                </a>
              </div>
                
              
            </div>
            
          </motion.div>
          <motion.div
          variants={fadeIn('left',0.3)}
          initial="hidden"
          whileInView={'show'}
          viewport={{once:false , amount:0.3}}
          className='flex-1 my-auto h-[640px] mix-blend-lighten content-center'>
           <iframe className='rounded-lg' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56647.204348406376!2d55.24153460097363!3d-21.002749002378554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x218288b199fec3e9%3A0xcd75253c6f21188d!2sSaint-Paul%2097460%2C%20La%20R%C3%A9union!5e1!3m2!1sfr!2sfr!4v1718210499013!5m2!1sfr!2sfr" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </motion.div>
        
        </div>
        
      </div>


    </section>

  );
};

export default Contact;
