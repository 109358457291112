import React from 'react';

import Logo from '../assets/logo.png';

const Header = () => {
  return (
  <header className='py-8'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>

      <a href='#'>
        <img className='w-2/5' src={Logo} alt=''/>
      </a>
      <a href="#contact" className='btn btn-lg content-center'>Contactez-moi</a>
      </div>
    </div>
  
  </header>);
};

export default Header;
