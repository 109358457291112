import React from 'react';
import CountUp from 'react-countup';

import { useInView } from 'react-intersection-observer';

import Image from '../assets/work.jpg';

import {motion} from 'framer-motion';
import { fadeIn } from '../variants';

const About = () => {
  const [ref, inView] = useInView({
    threshold:0.5,
  })
  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen '>
          <motion.div
          variants={fadeIn('right',0.3)}
          initial="hidden"
          whileInView={'show'}
          viewport={{once:false , amount:0.3}}
          className='flex-1 my-auto h-[640px] mix-blend-lighten content-center'>
           <img className='rounded-xl w-auto ' src={Image} alt='' />
            </motion.div>
          <motion.div 
          variants={fadeIn('left',0.3)}
          initial="hidden"
          whileInView={'show'}
          viewport={{once:false , amount:0.3}}
          className='flex-1'>
            <h2 className='h2 text-accent'>À propos de moi</h2>
            <h3 className='h3 mb-4'>
              Je suis Développeur Freelance à la Réunion

            </h3>
            <p className='mb-6'>
              Vice-champion de la webcup Réunion 2024<br/>
              Étudiant concepteur/développeur d'applications chez EXPERNET<br/>
              Titulaire d'un bac+2 en développement web et web mobile
            </p>
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
            <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={2} duration={3}/> : null }
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Années <br/>
                  D'expériences
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={15} duration={3}/> : null }
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Projets <br/>
                  Réalisés
                </div>
              </div>
              
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={20} duration={3}/> : null }
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Frameworks <br/>
                  Utilisés ou testés
                </div>
              </div>
              
            </div>
              <div className='flex gap-x-8 items-center'>
                  <a href="#contact" className='btn btn-lg content-center'>Contactez-moi</a>
                  <a href='#work' className='text-gradient btn-link'>
                    Mon portfolio
                  </a>
              </div>
          </motion.div>
        
        </div>
        
      </div>


    </section>
  );
};

export default About;
